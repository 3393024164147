<template>
  <div>
    <div v-if="noIntegrations">No integration partners added yet</div>
    <loader :loading="loading" :backdrop="true"/>
    <div v-for="(category, name) in categories" :key="name" class="integration-category">
      <div class="label">{{name}}</div>
      <div class="tiles">
        <div
          v-for="partner in category"
          :key="partner.uuid"
          class="tile"
          @click="handlePartnerClick(partner)"
        >
          <div class="logo">{{partner.name[0].toUpperCase()}}</div>
          <div>
            <div class="name">{{partner.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Loader from "@/components/ui/Loader";
  import NotifyMixin from "@/mixins/NotifyMixin";

  export default {
    name: "CustomerIntegrationsList",
    components: {Loader},
    mixins: [ModalNavigation, NotifyMixin],
    computed: {
      communityId() {
        return this.$route.params.communityId;
      },
      categories() {
        return this.partners.reduce((categories, partner) => {
          partner.purposes.forEach(purpose => {
            if (purpose.active) {
              if (!categories[purpose.category.name]) {
                categories[purpose.category.name] = [];
              }

              if(!categories[purpose.category.name].find(p => p.uuid === partner.uuid)) {
                categories[purpose.category.name].push(partner);
              }
            }
          });

          return categories;
        }, {})
      },
      noIntegrations() {
        return !Object.keys(this.categories).length
      }
    },
    data() {
      return {
        loading: false,
        partners: [],
      }
    },
    methods: {
      fetchPartners() {
        this.loading = true;
        this.$partnerDataProvider.getList('communityPartners', {communityId: this.communityId})
          .then(({content}) => {
            this.partners = content;
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => this.loading = false);
      },
      handlePartnerClick({uuid: partnerId}) {
        this.$router.replace({
          name: this.$route.params?.passFlowTo ? this.$route.params.passFlowTo : this.$route.name.replace('index', 'edit'),
          params: {
            ...this.$route.params,
            partnerId
          }
        });
      }
    },
    mounted() {
      this.$authDataProvider.getOne('communities', {id: this.communityId, customerId: this.$route.params.customerId})
        .then(community => this.setActiveModalTitle(`${community.communityName} community integration partners`))
        .catch((error) => this.notifyError(error.message));

      this.fetchPartners();
    }
  }
</script>

<style scoped>
.integration-category {
  @apply py-2;
}
</style>
